import CallEffect from '@/components/callEffect';
import Form from '@/components/form';
import FormDatePicker from '@/components/form/fields/datePicker';
import FormTextField from '@/components/form/fields/textField';
import FormattedTextField from '@/components/formattedTextField';
import Loading from '@/components/loading';
import { mutateGraphQL, useGraphQL } from '@/data';
import { CommerceRead, CommerceWrite } from '@/data/commerce/commerce.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import idPick from '@/helpers/idPick';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { Statuses } from '@/pages/dashboard/commerce/components/statuses';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { MutationCommerceWriteArgs, QueryCommerceReadArgs } from '@/types/schema';
import { Close as CloseIcon } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Fade,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import { round, startCase, toLower } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const calculateTotalPayments = ( payments ) => payments.reduce( ( total,
	payment ) => total + Number( payment.amount ), 0 );

export default function SchedulePaymentsModal( { id }: { id: string } ) {
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const event = useEvents();
	const { t } = useTranslation();
	
	const { data, isLoading } = useGraphQL<QueryCommerceReadArgs>( {
		query    : CommerceRead,
		queryKey : [ 'commerceRead' ],
		variables: { id },
	}, { placeholderData: undefined, enabled: Boolean( id ) } );
	
	const commerce = data?.commerceRead;
	
	const [ expanded, setExpanded ] = useState<boolean[]>( [] );
	
	const remaining = commerce?.grandTotal - ( commerce?.paidTotal || 0 );
	
	const handleExpand = ( index: number ) => ( _: any, isExpanded: boolean ) => {
		setExpanded( ( prev ) => {
			const newExpanded = [ ...prev ];
			newExpanded[ index ] = isExpanded;
			return newExpanded;
		} );
	};
	
	if ( isLoading ) return <Loading/>;
	
	return (
		<Form
			noClose
			initialValues={{
				payments: commerce?.scheduledPayments?.map( ( payment ) => ( {
					amount : round( remaining * payment.percent / 100, 2 ),
					percent: payment.percent,
					reason : payment.reason,
					dueDate: payment.dueDate,
					id     : payment.id,
					paid   : payment.paid,
				} ) ) || [],
			}}
			onSubmit={async ( values ) => {
				if ( values.payments?.find( ( payment ) => payment.amount <= 0 || payment.percent <= 0 ) ) {
					enqueueSnackbar( t( 'common:no-zero-payment' ), { variant: 'default' } );
					return;
				}
				if ( values.payments?.find( ( payment ) => !payment.dueDate ) ) {
					enqueueSnackbar( t( 'common:payment-date-required' ), { variant: 'default' } );
					return;
				}
				const dates = values.payments.map( ( payment ) => payment.dueDate );
				
				let sortedDates = true;
				if ( dates.length === 1 ) {
					sortedDates = true;
				} else {
					for ( let i = 1; i < dates.length; i++ ) {
						if ( isBefore( dates[ i ], dates[ i - 1 ] ) ) {
							sortedDates = false;
						}
					}
				}
				
				if ( !sortedDates ) {
					enqueueSnackbar( t( 'common:date-ascending-order' ), { variant: 'default' } );
					return;
				}
				const lastDueDate = values.payments[ values.payments.length - 1 ]?.dueDate;
				await mutateGraphQL<MutationCommerceWriteArgs>( {
					mutation : CommerceWrite,
					variables: {
						id   : commerce?.id,
						input: {
							type             : commerce?.type,
							scheduledPayments: values.payments.map( ( payment ) => ( {
								...idPick( payment, [ 'reason', 'dueDate' ] ),
								percent: payment.percent,
								staff  : commerce?.staff?.id || staff?.id,
								client : commerce?.client?.id || null,
								company: commerce?.company.id,
							} ) ),
							dueDate          : commerce?.dueDate ? isAfter( lastDueDate, commerce?.dueDate )
								? lastDueDate
								: commerce?.dueDate : lastDueDate,
						},
					},
				} );
				
				event.emit( 'reload.graphqlQuery', true );
				
				closeModal();
			}}>
			{( formik ) => {
				const totalPayments = calculateTotalPayments( formik.values.payments );
				const remainder = remaining - totalPayments;
				
				return (
					<ResponsiveModalContainer
						loading={formik.isSubmitting}
						closeOnSave={false}
						title={t( 'common:schedule-payment' )}
						secondaryTitle={`${t( 'common:schedule-payment-des-one' )} ${toLower( commerce?.type )}. ${t( 'common:schedule-payment-des-two' )}`}
						onSave={() => formik.handleSubmit()}>
						<CallEffect
							func={() => {
								formik.setFieldValue( 'payments', commerce?.scheduledPayments?.map( ( payment ) => ( {
									amount : round( remaining * payment.percent / 100, 2 ),
									percent: payment.percent,
									reason : payment.reason,
									id     : payment.id,
									paid   : payment.paid,
									dueDate: payment.dueDate,
								} ) ) || [] );
							}}
							deps={[ commerce?.scheduledPayments ]}
						/>
						<Stack spacing={3}>
							<Box>
								{!commerce.number.includes( 'Draft' ) && (
									<Typography color='text.secondary'>
										{`(#${commerce?.metadata?.customNumber || commerce?.number})`}
									</Typography>
								)}
								<Typography variant='h1'>
									{currencyFormat( remaining )}
								</Typography>
								<Button
									variant='text'
									color='primary'
									disabled={remainder <= 0 || formik.values.payments?.length >= 10}
									sx={{ alignSelf: 'end', my: 2 }}
									onClick={() => {
										const payments = [ ...formik.values.payments ];
										payments.push( {
											id     : nanoid(),
											amount : 0,
											percent: 0,
											dueDate: null,
											reason : '',
										} );
										formik.setFieldValue( 'payments', payments );
										setExpanded( () => {
											const newExpanded = new Array( payments.length ).fill( false );
											newExpanded[ newExpanded.length - 1 ] = true;
											return newExpanded;
										} );
									}}>
									{t( 'common:add-payment' )}
								</Button>
								<Box>
									{formik.values.payments.map( ( payment, index ) => {
										
										const paymentDetails = formik.values?.payments;
										
										const maxAmount = round( remaining - formik.values.payments
											.slice( 0, index )
											.reduce( ( total, payment ) => total + Number( payment.amount ), 0 ), 2 );
										
										const maxPercent = round( 100 - formik.values.payments
											.slice( 0, index )
											.reduce( ( total, payment ) => total + Number( payment.percent ), 0 ), 2 );
										
										const paidScheduledPayment = paymentDetails[ index ].paid;
										
										return (
											<Fade key={index} in timeout={500}>
												<Accordion
													sx={{ bgcolor: 'background.default', border: 0 }}
													expanded={expanded[ index ] || false}
													onChange={handleExpand( index )}>
													<AccordionSummary>
														<Stack
															direction='row'
															alignItems='center'
															justifyContent='space-between'
															sx={{ width: '100%' }}
															px={.5}>
															<Stack>
																<Typography component='span'>
																	{`Payment ${index + 1}: ${currencyFormat( paymentDetails[ index ]?.amount )}`}
																	<Typography color='text.secondary' component='span' mx={1}>
																		({round( paymentDetails[ index ]?.percent || 0, 2 ) || ''}%)
																	</Typography>
																</Typography>
																<Typography color='text.secondary'>
																	{paymentDetails[ index ]?.dueDate && format( paymentDetails[ index ]?.dueDate, 'PP' )}
																</Typography>
																{paidScheduledPayment && (
																	<Typography sx={{ color: `${Statuses.PAID?.color}.main` }}>
																		{Statuses.PAID?.label}
																	</Typography>
																)}
																<Typography
																	sx={{ mr: 3, WebkitLineClamp: 2, color: 'text.secondary' }}
																	className='ellipsisText'>
																	{paymentDetails[ index ]?.reason}
																</Typography>
															</Stack>
															{!paidScheduledPayment && (
																<Box>
																	<Tooltip title='Remove'>
																		<IconButton
																			color='error'
																			onClick={() => {
																				const payments = [ ...formik.values.payments ];
																				payments.splice( index, 1 );
																				formik.setFieldValue( 'payments', payments );
																			}}>
																			<CloseIcon/>
																		</IconButton>
																	</Tooltip>
																</Box>
															)}
														</Stack>
													</AccordionSummary>
													<AccordionDetails>
														<Stack spacing={2}>
															<Stack
																direction='row'
																alignItems='center'
																justifyContent='space-between'
																px={.5}>
																<Typography>
																	{t( 'common:date-payment' )}
																</Typography>
																<Box>
																	<FormDatePicker
																		name={`payments[${index}].dueDate`}
																		disabled={paidScheduledPayment}
																		minDate={index === 0 || !formik.values.payments?.[ index - 1 ]?.dueDate
																			? new Date()
																			: addDays( formik.values.payments?.[ index - 1 ]?.dueDate, 1 )}
																	/>
																</Box>
															</Stack>
															<Stack
																direction='row'
																alignItems='center'
																justifyContent='space-between'
																px={.5}>
																<Typography>
																	{t( 'common:amount' )}
																</Typography>
																<Box>
																	<FormattedTextField
																		value={formik.values.payments[ index ].amount}
																		type='number'
																		sx={{ width: 150 }}
																		disabled={paidScheduledPayment}
																		InputProps={{
																			startAdornment: (
																				<Typography
																					color='text.secondary'
																					pr={.5}>
																					$
																				</Typography>
																			),
																			inputProps    : { max: maxAmount },
																		}}
																		onFocus={( event ) => event.target.select()}
																		onChange={( e ) => {
																			const payments = formik.values.payments;
																			const amount = +e.target.value;
																			if ( amount <= remaining ) {
																				if ( amount > maxAmount ) {
																					payments[ index ].amount = maxAmount;
																					payments[ index ].percent = +( maxAmount / remaining * 100 );
																				} else {
																					payments[ index ].amount = +e.target.value;
																					payments[ index ].percent = +( amount / remaining * 100 );
																				}
																				formik.setFieldValue( 'payments', payments );
																			}
																		}}
																	/>
																</Box>
															</Stack>
															<Stack
																direction='row'
																alignItems='center'
																justifyContent='space-between'
																px={.5}>
																<Typography>
																	{t( 'common:percent' )}
																</Typography>
																<Box>
																	<FormattedTextField
																		value={round( formik.values.payments[ index ].percent, 2 )}
																		type='number'
																		sx={{ width: 150 }}
																		disabled={paidScheduledPayment}
																		InputProps={{
																			startAdornment: (
																				<Typography
																					color='text.secondary'
																					pr={.5}>
																					%
																				</Typography>
																			),
																			inputProps    : { max: maxPercent },
																		}}
																		onFocus={( event ) => event.target.select()}
																		onChange={( e ) => {
																			const payments = formik.values.payments;
																			const percent = +e.target.value;
																			if ( percent <= 100 ) {
																				if ( percent > maxPercent ) {
																					payments[ index ].percent = maxPercent;
																					payments[ index ].amount = round( +( maxPercent / 100 * remaining ), 2 );
																				} else {
																					payments[ index ].percent = percent;
																					payments[ index ].amount = round( +( percent / 100 * remaining ), 2 );
																					
																				}
																				formik.setFieldValue( 'payments', payments );
																			}
																		}}
																	/>
																</Box>
															</Stack>
															<FormTextField
																fullWidth
																multiline
																disabled={paidScheduledPayment}
																rows={3}
																label={t( 'common:reason' )}
																name={`payments[${index}].reason`}
																placeholder={t( 'common:reason-payment' )}
															/>
														</Stack>
													</AccordionDetails>
												</Accordion>
											</Fade>
										);
									} )}
								</Box>
							</Box>
							<Box
								sx={{
									p       : 2,
									bgcolor : 'background.default',
									position: 'absolute',
									bottom  : 62,
									left    : 0,
									right   : 0,
									width   : '100%',
								}}>
								{commerce?.dueDate && (
									<Typography gutterBottom color='text.secondary'>
										{startCase( toLower( commerce.type ) )} Due Date:
										<Typography
											component='span'
											color='text.primary'
											variant='h4'
											fontWeight='500'
											px={1}>
											{safeFormatInTimeZone( commerce.dueDate, 'PP' )}
										</Typography>
									</Typography>
								)}
								<Typography color='text.secondary'>
									Remaining:
									<Typography
										component='span'
										color='text.primary'
										variant='h4'
										fontWeight='500'
										px={1}>
										{remainder > 0 ? currencyFormat( remainder ) : '$0.00'}
									</Typography>
								</Typography>
							</Box>
						</Stack>
					</ResponsiveModalContainer>
				);
			}}
		</Form>
	);
}

