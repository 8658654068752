import TextFieldInputLabel from '@/components/form/inputLabel';
import Loading from '@/components/loading';
import { mutateGraphQL, TEMP_HARDCODED_ERROR, useGraphQL } from '@/data';
import { CommerceWrite } from '@/data/commerce/commerce.graphql';
import { DevicesRead } from '@/data/device.graphql';
import CloverDeviceModal from '@/pages/dashboard/settings/devices/cloverDeviceModal';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal, useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Device, type MutationCommerceWriteArgs, Order, QueryDevicesReadArgs } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import { sendNotificationToDevice } from '@/utils/api/sendNotificationToDevice';
import { Button, Fade, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';

async function payOnOrderImport( cardHolderName, cardHolderPhone, invoice ) {
	try {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
			id         : invoice.id,
			reverseSync: true,
		} );
		
		if ( cardHolderName || cardHolderPhone ) {
			await mutateGraphQL<MutationCommerceWriteArgs>( {
				mutation : CommerceWrite,
				variables: {
					id   : invoice.id,
					input: {
						metadata: {
							cardInfo: invoice.metadata?.cardInfo ? {
								...invoice.metadata.cardInfo,
								[ invoice.externalId || invoice.id ]: [ cardHolderName || '', cardHolderPhone || '' ],
							} : {
								[ invoice.externalId || invoice.id ]: [ cardHolderName || '', cardHolderPhone || '' ],
							},
						},
					},
				},
			} );
		}
		
	} catch ( e ) {
		console.error( e );
		throw new Error( TEMP_HARDCODED_ERROR );
	}
}

export default function CloverDeviceSelectionModal( { invoice }: { invoice: Order } ) {
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const { closeModal } = useModalControls();
	const { showModal } = useModal();
	
	const [ cloverDevicesOpened, setShowCloverDevicesOpened ] = useState( false );
	const [ linkedToStaffDevice, setLinkedToStaffDevice ] = useState<Device>( null );
	const [ linkingToStaffCheckLoading, setLinkingToStaffCheckLoading ] = useState( false );
	const [ cardHolderName, setCardHolderName ] = useState( '' );
	const [ cardHolderPhone, setCardHolderPhone ] = useState( '' );
	
	const { data, isFetching, refetch } = useGraphQL<QueryDevicesReadArgs>( {
		queryKey : 'devices',
		query    : DevicesRead,
		variables: {
			options: {
				limit : 30,
				filter: {
					productName: { $ne: 'Station 2018' },
					gateway    : invoice?.gateway?.id,
				},
			},
		},
	} );
	
	const devices = data?.devicesRead?.items;
	
	async function payOnClover( device: Device ) {
		try {
			const { data } = await sendNotificationToDevice( invoice, device );
			if ( data?.sent ) {
				enqueueSnackbar( `Your ${device?.productName} is opening the checkout page for this invoice`, { variant: 'success' } );
			}
			await postCloverMeteredBilling( {
				orderId  : invoice.id,
				gatewayId: invoice.gateway.id,
				eventType: 'Pay on Clover',
				key      : 'payOnClover',
				staffId  : staff.id,
				clientId : invoice.client?.id,
			} )
				.catch( () => null );
			setShowCloverDevicesOpened( true );
		} catch {
			enqueueSnackbar( 'Failed to open the checkout page on the device', { variant: 'error' } );
		} finally {
			setLinkingToStaffCheckLoading( false );
		}
	}
	
	useAsyncEffect( async () => {
		if ( isEmpty( devices ) ) return;
		for ( const device of devices ?? [] ) {
			const staffIds = device?.staffs?.map( ( s ) => s.id );
			if ( staffIds?.includes( staff?.id ) ) {
				setLinkingToStaffCheckLoading( true );
				setLinkedToStaffDevice( device );
				await payOnClover( device );
				return;
			}
		}
	}, [ devices ] );
	
	if ( isFetching || linkingToStaffCheckLoading ) return <Loading/>;
	
	if ( isEmpty( devices ) ) return (
		<Fade in timeout={1000}>
			<Typography p={3} className='center'>
				No devices available for this merchant. Please import your devices from the settings.
			</Typography>
		</Fade>
	);
	
	return (
		<ResponsiveModalContainer
			saveButtonText={cloverDevicesOpened ? 'Done' : 'Open Checkout'}
			saveButtonProps={{ disabled: !linkedToStaffDevice }}
			closeOnSave={false}
			title='Pay On Clover'
			secondaryTitle='Select a device to complete the checkout'
			onSave={async () => {
				if ( cloverDevicesOpened ) {
					await payOnOrderImport( cardHolderName, cardHolderPhone, invoice );
					closeModal();
				} else {
					// Will run the function above
					await payOnClover( linkedToStaffDevice );
				}
			}}>
			{cloverDevicesOpened ? (
				<Stack>
					<Typography className='center' variant='h4'>
						Complete the payment on your
						clover {linkedToStaffDevice.label || linkedToStaffDevice.productName} device
					</Typography>
					{staff.company.metadata?.cardInfo && (
						<Stack spacing={1} my={1}>
							<TextField
								fullWidth
								label='Cardholder name'
								placeholder={t( 'common:card-holder-name' )}
								value={cardHolderName}
								onChange={( e ) => setCardHolderName( e.target.value )}
							/>
							<TextField
								fullWidth
								label='Phone'
								placeholder={t( 'common:phone' )}
								value={cardHolderPhone}
								onChange={( e ) => setCardHolderPhone( e.target.value )}
							/>
						</Stack>
					)}
					<Loading/>
					<Typography className='center' color='warning.main'>
						Press done when complete
					</Typography>
				</Stack>
			) : (
				<Stack>
					{!isEmpty( devices ) ? (
						<Stack>
							<TextFieldInputLabel label='Select device to pay'/>
							<Select
								fullWidth
								onChange={( e ) => {
									setLinkedToStaffDevice( devices.find( ( d ) => d.id === e.target.value ) );
								}}>
								{devices?.map( ( device, i ) => (
									<MenuItem key={i} value={device.id || ''}>
										{device?.label || device.productName} ( {device?.label && device?.productName} {device?.serial} )
									</MenuItem>
								) )}
							</Select>
						</Stack>
					) : (
						<Stack spacing={1}>
							<Typography color='text.secondary'>
								You have no devices imported from Clover, please import a device to process a refund.
							</Typography>
							<Button
								sx={{ alignSelf: 'start' }}
								variant='text'
								onClick={() => showModal( CloverDeviceModal, { maxWidth: 'xs' }, {
									onSubmit: async () => await refetch(),
								} )}>
								Import Devices
							</Button>
						</Stack>
					)}
				</Stack>
			)}
		</ResponsiveModalContainer>
	);
}
